import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const IdareHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | İDARE HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>İDARE HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.webp")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            İdare hukuku, özetle kişinin devlet ya da kamu kurumları ile aralarındaki anlaşmazlıklarının çözüme bağlandığı hukuk alanıdır. Bu kapsamda genel olarak iptal davası, tam yargı davası ve vergi davaları olarak karşınıza çıkar. Bir kamu kurumunun sizinle ilgili işlem ve eylemi hukuka aykırıysa iptal davası açmanız gereklidir. Eğer bir kamu kurumunun işlem ve eyleminden maddi veya manevi zarar görmüşseniz tam yargı davası açmanız gereklidir. Eğer vergi kurumlarının sizinle ilgili işlem ve eylemleri hukuka aykırıysa bu durumda da vergi davası açmanız gereklidir.
            </p>
            <br></br>
            <p>
            İdare ve Vergi Mahkemelerinin kararlarına karşı kural olarak önce Bölge İdare Mahkemelerine daha sonra da Danıştay’a başvurmak gereklidir. Bu başvurularda da süre kaçırmamaya çok dikkat edilmelidir. 
            </p>
            <br></br>
            <p>
            İdare hukuku alanı çok teknik bir alandır. Özellikle İdari Yargı Usulü Kanunu’nda belirlenen sürelere riayet çok önemlidir. Bu süreleri kaçırmanız durumunda geri dönülmez hak kayıpları yaşanmaktadır. Bu nedenle de, idare hukuku uyuşmazlıklarında bir avukat tutmanız tavsiye edilir. Büromuzda idare hukuku alanında tecrübeli avukat kadromuz ile tüm idare hukuku uyuşmazlıklarında size hukuki destek vermeye hazırız.  
            </p>


            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
    <AccordionTab header="İptal Davası Nedir?">
        <p className="m-0">
        İptal davası genel olarak hukuka aykırı olduğu düşünülen idari eylem ve işlemlerin iptali için açılan davalardır. İptal davası, idare hukukunda ve idari yargıda çok önemli bir yere sahiptir. İdare Mahkemelerinin sıklıkla karşı karşıya kaldığı dava türlerinden biri olan iptal davaları, idari işlemlerin hukuka aykırılığı iddiası ile açılan davalardır. Hukukun evrensel kurallarından olan yargı denetimi kavramı çerçevesinde işlem ve eylemlerin hukuka uygunluk denetimi sürecinde iptal davaları önemli bir rol oynar. Bu önemi nedeniyle ülkemizde ve birçok ülkede ayrı bir yargılama kolu olarak düzenlenmiştir. Ancak ABD gibi bazı ülkelerde ise idare hukuku davaları, diğer hukuk davaları içinde çözümlenir. <b>Büromuza başvurmanız halinde ise aleyhinize gerçekleştirilen idari işlem veya eylemin en doğru ve hukuki bir şekilde iptalini sağlamamız mümkündür.</b>
        </p>
    </AccordionTab>
    <AccordionTab header="İptal Davası Açma Süresi Ne kadardır?">
        <p className="m-0">
        İptal davası açma süresi esasen idari işlemin niteliğine göre değişmektedir. Genel dava açma süresi Danıştay ve idare mahkemeleri için idari işlemden itibaren 60 gün iken bu süre vergi mahkemelerinde 30 gündür. Bunlar iptal davasında hak düşürücü sürelerdir. <br />
        Mevzuatta bazı idari işlemler için özel dava açma süreleri yer alır. Bunlar genellikle idari işlemde dava açma süresi olarak belirtilir. Böyle durumlarda o sürelere uyulur.<br />
        Eğer idari işlemde bu özel sürelere yer verilmemişse bu genel sürelere göre dava açılır. <b>Büromuza başvurmanız halinde ise aleyhinize gerçekleştirilen idari işlem veya eylemin en doğru ve hukuki bir şekilde iptalini sağlamamız mümkündür.</b>
        </p>
    </AccordionTab>
    <AccordionTab header="İptal Davası ile birlikte Tam Yargı Davası Birlikte Açılabilir mi?">
        <p className="m-0">
        Tam yargı davası ve iptal davası birlikte açılabileceği gibi iptal davasının sonucunda verilen iptal kararından sonra da tam yargı davası açılabilir. Bu durumda kesinleşen iptal kararının tebliğinden itibaren davacının 60 gün içerisinde tam yargı davasını açması gerekir. Bu nedenle her hangi bir hak kaybı yaşanmadığı için, dava masraf ve harçlarını hesaplıyorsanız ve riske atmak istemiyorsanız, önce iptal davasını daha sonra ise tam yargı davasını açmanız önerilir. <b>Büromuza başvurmanız halinde ise aleyhinize gerçekleştirilen idari işlem veya eylemden kaynaklanan maddi veya manevi zararlarınızın tazminini sağlamamız mümkündür.</b>
        </p>
    </AccordionTab>
    {/* <AccordionTab header="İdare Hukuku nedir?">
        <p className="m-0">
            İdare hukuku, devletin ve kamu idarelerinin faaliyetlerini düzenleyen, idarenin yetki ve sorumluluklarını belirleyen hukuk dalıdır.
        </p>
    </AccordionTab>
    <AccordionTab header="İdare Hukuku'nun temel prensipleri nelerdir?">
        <p className="m-0">
            İdare hukukunun temel prensipleri hukuka uygunluk, kamu yararı, eşitlik, tarafsızlık, şeffaflık ve hukuki güvencedir.
        </p>
    </AccordionTab>
    <AccordionTab header="İdari eylem ve işlemler neleri kapsar?">
        <p className="m-0">
            İdari eylem ve işlemler, idarenin yetki kullanımı sonucunda ortaya çıkan eylem ve kararlardır. Bunlar idari işlem türlerine göre farklılık gösterebilir.
        </p>
    </AccordionTab>
    <AccordionTab header="İdareye karşı dava açma süreci nasıl işler?">
        <p className="m-0">
            İdareye karşı dava açma sürecinde, idari işlem veya eylemin iptali, idarenin eylem ve işlemlerinden kaynaklanan tazminat talepleri gibi konular gündeme gelebilir. İdari dava süreci genellikle idari yargı mercilerinde yürütülür.
        </p>
    </AccordionTab>
    <AccordionTab header="İdari sözleşmeler ve idari hukuk ilişkisi nedir?">
        <p className="m-0">
            İdari sözleşmeler, idare ile gerçek veya tüzel kişiler arasında yapılan sözleşmelerdir ve idare hukukunun önemli bir konusunu oluşturur.
        </p>
    </AccordionTab>
    <AccordionTab header="İdare hukuku ve vergi hukuku ilişkisi nedir?">
        <p className="m-0">
            İdare hukuku ve vergi hukuku arasındaki ilişki, vergi idaresinin yetkilerinin ve mükelleflerin haklarının düzenlenmesinde ortaya çıkar.
        </p>
    </AccordionTab>
    <AccordionTab header="Ekşioğlu Hukuk Bürosu'nda idare hukuku alanında nasıl hizmet verilmektedir?">
        <p className="m-0">
            Ekşioğlu Hukuk Bürosu, müvekkillerine idari davalar, idari işlemlerin iptali, idari sözleşmelerin hazırlanması ve incelenmesi gibi idare hukuku konularında hukuki danışmanlık ve temsil hizmetleri sunmaktadır.
        </p>
    </AccordionTab> */}
</Accordion>

          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default IdareHukuku;
