import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const BilisimHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | BİLİŞİM HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>BİLİŞİM HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Bilişim Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.webp")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            Bilişim hukuku, hızla gelişen teknoloji ve internet dünyasının beraberinde getirdiği hukuki sorunları çözmek amacıyla ortaya çıkmış bir hukuk dalıdır. Bilişim hukukunun kapsamı oldukça geniştir ve birçok farklı alanı içerir. Bunlar arasında siber suçlar, veri koruma , kişisel verilerin korunması, fikri mülkiyet hakları, elektronik ticaret ve internet üzerindeki diğer hukuki işlemler, dijital sözleşmeler ve internet üzerindeki içerik düzenlemeleri yer alır. Bu hukuk dalı, hem bireylerin hem de kurumların dijital ortamda karşılaştıkları hukuki sorunları ele alır ve bu sorunlara çözüm bulmayı amaçlar.
            </p>
            <br></br>
            <p>
            Bilişim hukukunun en önemli alanı ise siber suçlardır. Siber suçlar, bilgisayar sistemleri, ağlar ve internet gibi bilişim teknolojileri kullanılarak işlenen suçları ifade eder. Bu tür suçlar arasında; veri hırsızlığı, kimlik hırsızlığı, kötü amaçlı yazılım yayma, bilgisayar sistemlerine izinsiz erişim (hacking), çevrimiçi dolandırıcılık ve hizmet reddi saldırıları (DDoS) gibi çeşitli eylemler bulunur. Siber suçlar, hem bireysel kullanıcıları hem de büyük kuruluşları hedef alabilir ve maddi manevi zararlara yol açabilir. Ayrıca, bu suçlar genellikle anonim olarak işlendiği ve sınır tanımadığı için, faillerin tespit edilmesi ve yakalanması zor olabilir.
            </p>
            
            



            </Col>
          </Row>
          <Row>
            
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="12"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            
            <p>
            Kimlik hırsızlığı en yaygın siber suçlardan biridir ve kişilerin kimlik bilgileri çalınarak banka hesaplarına erişim sağlanabilir, bu da maddi kayıplara yol açar. Fidye yazılımları ise kurumsal düzeyde büyük mağduriyetlere neden olur; saldırganlar, şirketlerin verilerini şifreleyerek bu verilerin geri verilmesi karşılığında fidye talep ederler. Bu tür saldırılar, işletmelerin operasyonlarını durma noktasına getirebilir ve büyük finansal zararlar doğurabilir. Ayrıca, çevrimiçi dolandırıcılık ve kimlik avı saldırıları, kullanıcıların kişisel ve finansal bilgilerini ele geçirmek için sahte e-postalar ve web siteleri kullanarak bireyleri kandırmaya çalışır. Sosyal medya platformlarında hesapların ele geçirilmesi, hem kişisel gizliliğin ihlaline hem de itibar kaybına yol açabilir. Bu tür siber suçlar, modern dünyada dijital güvenliğin önemini bir kez daha gözler önüne sermektedir ve hem bireyler hem de kurumlar için ciddi tehditler oluşturmaktadır. 
            </p>
            <br></br>
            <p>
            Uluslararası işbirliği, siber suçların sınır ötesi doğası nedeniyle kritik öneme sahiptir; bu nedenle, ülkeler arası bilgi paylaşımı ve ortak operasyonlar, siber suçlarla etkin mücadelede önemli rol oynar. Kullanıcılar ise, güçlü şifreler kullanmak, güvenlik yazılımlarını güncel tutmak ve bilinçli internet kullanımı gibi bireysel önlemler alarak siber suçlardan korunmaya çalışmalıdır.
            </p>



            </Col>
          </Row>

          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
    <AccordionTab header="İnternet üzerinden yapılan alışverişlerde güvenliği nasıl sağlayabilirim?">
        <p className="m-0">
        İnternet üzerinden yapılan alışverişlerde güvenliği sağlamak için SSL (Güvenli Soket Katmanı) sertifikalı siteleri tercih etmek, güvenilir ödeme yöntemleri kullanmak ve satıcıların güvenilirliğini araştırmak önemlidir. Ayrıca, alışveriş esnasında kişisel ve finansal bilgilerinizi korumak için dikkatli olmalısınız.
        </p>
    </AccordionTab>
    <AccordionTab header="Sosyal medya hesabım çalındı ne yapabilirim?">
        <p className="m-0">
        Sosyal medya kullanıcılarının hesaplarını çalmak ve bu hesaplara çeşitli yollarla erişim sağlayarak hesaplardaki verileri değiştirmek, yok etmek TCK 243. ve 244. maddeleri kapsamında suçtur. Dolayısıyla hesabınızın çalındığını fark ettiğiniz anda yapmanız gereken en önemli hukuki işlem, savcılığa suç duyurusunda bulunmak olacaktır.
        </p>
    </AccordionTab>
    <AccordionTab header="İnternetteki içerik paylaşımlarında yasal sorumluluğum nedir?">
        <p className="m-0">
        İnternette içerik paylaşırken, yasal olarak izin verilen içerikleri paylaşmak ve telif haklarına saygı göstermek önemlidir. Ayrıca, hakaret, iftira, kişisel verilerin ihlali gibi suç teşkil eden içeriklerden kaçınmak ve platformların kullanım koşullarını takip etmek gereklidir.
        </p>
    </AccordionTab>
    <AccordionTab header="Sosyal medya hesaplarımın güvenliğini nasıl sağlayabilirim?">
        <p className="m-0">
        Sosyal medya hesaplarının güvenliği için güçlü şifreler kullanmak, iki faktörlü kimlik doğrulama özelliğini etkinleştirmek ve hesap güvenlik ayarlarını düzenli olarak kontrol etmek önemlidir. Ayrıca, sahte hesaplar ve dolandırıcılık girişimlerine karşı dikkatli olunmalıdır.
        </p>
    </AccordionTab>
    <AccordionTab header="Çevrimiçi dolandırıcılıkla karşılaştığımda ne yapmalıyım?">
        <p className="m-0">
        Çevrimiçi dolandırıcılık durumunda, hemen bankanız veya ilgili kuruluşla iletişime geçerek durumu bildirmek önemlidir. Ayrıca, dolandırıcılık girişimini ilgili makamlara bildirmek ve gerekli yasal adımları atmak gerekebilir.
        </p>
    </AccordionTab>
    {/* <AccordionTab header="Bilişim Hukuku nedir?">
        <p className="m-0">
            Bilişim hukuku, bilgi teknolojileri, internet kullanımı, veri güvenliği, elektronik ticaret gibi dijital dünyaya ilişkin konuları düzenleyen hukuk dalıdır. Bilişim hukuku, bilgi teknolojileri alanındaki gelişmeleri hukuki çerçeveye oturtmayı amaçlar.
        </p>
    </AccordionTab>
    <AccordionTab header="Veri güvenliği ve gizliliği neden önemlidir?">
        <p className="m-0">
            Veri güvenliği ve gizliliği, kullanıcıların kişisel verilerinin korunması, çevrimiçi güvenliğin sağlanması ve veri ihlallerinin önlenmesi için kritik öneme sahiptir. Bilişim hukuku bu konuları düzenleyerek kullanıcıların haklarını korur.
        </p>
    </AccordionTab>
    <AccordionTab header="Elektronik ticaret hukuku nedir?">
        <p className="m-0">
            Elektronik ticaret hukuku, internet üzerinden yapılan alışverişlerde tarafların hak ve yükümlülüklerini düzenler. İnternet üzerinden satış, sözleşmeler, tüketici hakları gibi konular elektronik ticaret hukukunun kapsamına girer.
        </p>
    </AccordionTab>
    <AccordionTab header="Bilişim suçları nelerdir?">
        <p className="m-0">
            Bilişim suçları, bilgisayar korsanlığı, veri hırsızlığı, kötü amaçlı yazılımların yayılması gibi internet ortamında gerçekleştirilen suçları ifade eder. Bilişim hukuku bu tür suçların önlenmesi ve cezalandırılmasını sağlar.
        </p>
    </AccordionTab>
    <AccordionTab header="Yapay zeka ve bilişim hukuku ilişkisi nasıldır?">
        <p className="m-0">
            Yapay zeka, bilişim hukukunda yeni sorunlar ve tartışmaları beraberinde getirmiştir. Yapay zeka etik kuralları, sorumluluk ve hukuki düzenlemeler gibi konular bilişim hukukunu etkileyen önemli alanlardır.
        </p>
    </AccordionTab>
    <AccordionTab header="Ekşioğlu Hukuk Bürosu'nda bilişim hukuku alanında nasıl hizmet verilmektedir?">
        <p className="m-0">
            Ekşioğlu Hukuk Bürosu, müvekkillerine veri güvenliği danışmanlığı, elektronik ticaret hukuku uyuşmazlıkları, bilişim suçlarına karşı hukuki destek ve yapay zeka hukuku konularında hizmetler sunmaktadır.
        </p>
    </AccordionTab> */}
</Accordion>


          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default BilisimHukuku;
