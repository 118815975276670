import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const IsHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | İŞ VE SOSYAL GÜVENLİK HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>İŞ VE SOSYAL GÜVENLİK HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="İş ve Sosyal Güvenlik Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.webp")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            Alanında uzman avukat kadromuz ile iş hukukunun her alanında gerek arabuluculuk süreçlerinin yönetilmesi noktasında gerek ise dava süreçlerinde müvekkillerimize hukuki destek danışmanlık ve uyuşmazlıkların çözümü hizmetleri sunulmaktadır.
            </p>
            <br></br>
            <p>
            Bu kapsamda, İş Hukuku ile ilgili ihtilafların çözüme ulaştırılmasında karşı taraf ile müzakere edilmesi ve arabuluculuk süreçlerinin yönetilmesi ile uzlaşma sağlanması noktasında müvekkillerimize destek olmaktayız. Arabuluculuk süreçleri ile çözüme kavuşmayan ihtilafların ise yargısal süreçlerini son derece titizlikle yürüterek dava takibi yapılması noktasında müvekkillerimize hizmet sunmaktayız.
            </p>
            <br></br>
            <p>
            Bunun yanı sıra, İş Hukuku kapsamında insan kaynakları departmanlarına danışmanlık hizmeti sunmaktayız. Danışmanlık hizmeti olarak bireysel veya kurumsal müvekkillerimize fesih sürecinin yürütülmesi noktasında hukuki destek olmaktayız.
            </p>
            <br></br>
            <p>
            İş hukukundan kaynaklanan işe iade ve tazminat davalarının yanı sıra işyeri kazaları nedeniyle oluşan zararların tazmini ve iş sağlığı ve güvenliği konularına ilişkin olarak da müvekkillerimize kapsamlı hizmet vermekteyiz.
            </p>


            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
            <AccordionTab header="Kıdem tazminatı nedir?">
                <p className="m-0">
                Bir yıldan fazla süre çalışan işçilere işten ayrılırken ödenen tazminat türüdür. İşçinin kıdem tazminatına hak kazanabilmesi için; işçinin işten haklı bir nedenle ayrılmış olması veya işverenin işçinin sözleşmesini haklı bir nedene dayanmaksızın feshetmiş olması gerekir.
                </p>
            </AccordionTab>
            <AccordionTab header="Hangi hallerde İşe iade davası açılabilir ve işe iade davası süreye tabi midir?">
                <p className="m-0">
                İşyerinde 30 veya daha fazla işçi çalışıyor olması, işçinin altı aydan dana uzun süreli çalışıyor olması, işçinin belirsiz süreli iş sözleşmesi ile çalıştırılması, işçinin işveren vekili olmaması ve iş sözleşmesinin işveren tarafından feshedilmesi halinde işe iade davası açılabilecektir. Ancak işe iade davası iş sözleşmesinin feshinden itibaren bir ay içerisinde açılmalıdır. 
                </p>
            </AccordionTab>
            <AccordionTab header="İşe iade davasında arabulucuya başvurmak zorunlu mu ve başvuru süresi ne zamandır?">
                <p className="m-0">
                İşe iade davasında arabuluculuk yoluna başvurmak bir dava şartıdır. Başka bir deyişle davadan önce arabulucuya başvurulması zorunludur. Arabulucuya başvurma süresi ise iş sözleşmesinin feshinden itibaren iki haftadır.
                </p>
            </AccordionTab>
            <AccordionTab header="İş Hukuku nedir?">
                <p className="m-0">
                İş hukuku, işçi ve işveren arasındaki ilişkileri düzenleyen ve çalışma hayatını düzenleyen bir hukuk dalıdır.
                İş hukuku, çalışma koşulları, iş güvenliği, işçi hakları, işten çıkarma ve işe alma gibi konuları kapsar.
                </p>
            </AccordionTab>
            <AccordionTab header="Arabuluculuk görüşmeleri sonucunda anlaşmaya varılamaz ise ne olur?">
                <p className="m-0">
                Arabuluculuk Son tutanağın düzenlendiği tarihten itibaren iki hafta içinde İş Mahkemesinde dava açılabilecektir.
                </p>
            </AccordionTab>
            <AccordionTab header="İş Hukuku kimleri kapsar?">
                <p className="m-0">
                İş hukuku, işçiler, işverenler ve işveren vekilleri arasındaki hukuki ilişkileri kapsar. Tüm iş kollarında ve sektörlerde 
                geçerli olan bu hukuk dalı, işçi ve işveren haklarını ve yükümlülüklerini düzenler.
                </p>
            </AccordionTab>
            <AccordionTab header="İş Hukuku neden önemlidir?">
                <p className="m-0">
                İş hukuku, işçi ve işveren arasındaki adil ve dengeli ilişkiyi sağlamak için kritik öneme sahiptir. Çalışma koşullarının 
                iyileştirilmesi, iş güvenliğinin sağlanması ve işçi haklarının korunması iş hukuku ile güvence altına alınır.
                </p>
            </AccordionTab>
            <AccordionTab header="İş sözleşmesi nedir?">
                <p className="m-0">
                İş sözleşmesi, işçi ve işveren arasında yapılan ve işçinin iş görme, işverenin ise ücret ödeme borcunu doğuran bir anlaşmadır. 
                İş sözleşmesi yazılı veya sözlü olabilir ancak yazılı yapılması tarafların haklarının korunması açısından önemlidir.
                </p>
            </AccordionTab>
            <AccordionTab header="İşçi hakları nelerdir?">
                <p className="m-0">
                İşçi hakları, işçilerin çalışma hayatında sahip oldukları haklardır. Bu haklar arasında ücret hakkı, iş güvenliği ve sağlığı, 
                dinlenme ve izin hakları, sosyal güvenlik hakları ve iş güvencesi sayılabilir.
                </p>
            </AccordionTab>
            <AccordionTab header="İşten çıkarma durumunda neler yapılabilir?">
                <p className="m-0">
                İşten çıkarma durumunda işçiler, işe iade davası açabilir veya kıdem tazminatı, ihbar tazminatı gibi haklarını talep edebilirler. 
                Ekşioğlu Hukuk Bürosu, işten çıkarma durumlarında müvekkillerine hukuki destek sağlamaktadır.
                </p>
            </AccordionTab>
            <AccordionTab header="İş hukuku danışmanlık süreci nasıl işler?">
                <p className="m-0">
                İş hukuku danışmanlık süreci, işçi veya işverenin ihtiyaçlarının değerlendirilmesiyle başlar. Ardından, hukuki gereklilikler 
                doğrultusunda danışmanlık hizmeti sunulur ve gerekli hukuki adımlar atılır. Ekşioğlu Hukuk Bürosu, bu sürecin her aşamasında 
                profesyonel destek sağlar.
                </p>
            </AccordionTab>
            <AccordionTab header="İşçi ve işveren uyuşmazlıklarında ne yapılabilir?">
                <p className="m-0">
                İşçi ve işveren arasındaki uyuşmazlıklarda, arabuluculuk ve dava yoluna başvurulabilir. Uyuşmazlıkların çözümü için Ekşioğlu 
                Hukuk Bürosu, müvekkillerine arabuluculuk ve dava sürecinde hukuki destek sunmaktadır.
                </p>
            </AccordionTab>
            <AccordionTab header="Ekşioğlu Hukuk Bürosu'na kimler başvurabilir?">
                <p className="m-0">
                Ekşioğlu Hukuk Bürosu'na, iş hukuku alanında hukuki danışmanlık ve destek almak isteyen işçiler, işverenler ve kurumlar 
                başvurabilir. Her türlü iş hukuku ihtiyacı için profesyonel hukuki hizmet sunulmaktadır.
                </p>
            </AccordionTab>
          </Accordion>


          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default IsHukuku;
