import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const TicaretHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | TİCARET HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>TİCARET HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.webp")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            Ticaret Hukuku, gerçek ve tüzel kişiler arasında ticari ilişkileri düzenleyen hukuk dallarından biridir.
            Ticaretle uğraşan gerçek ve tüzel kişi niteliğindeki tacirler arasında oluşan nitelikli iş, işlem, ticaret
            hukuku çek konusu gibi ticari borçlanma ilişkilerini düzenlemektedir. Ticari işletmeyi ilgilendiren,
            kuruluş, yapılanma, çalışma şekilleri, sona erme ile ticari iş ve işlemlerde kullanılan kıymetli evraklara
            ilişkin kuralları ayrıntılı olarak sıralamaktadır. Ticaret Hukuku ayrıca, sigorta konusunu ticari bir faaliyet
            olarak kabul ederek, sigortalama kapsamındaki iş ve işlemlere de ayrıntılı olarak yer vermektedir.
            </p>
            <br></br>
            <p>
                <b>Ekşioğlu Hukuk Bürosu</b>, müvekkillerine ticaret hukuku konusunda kapsamlı hukuki danışmanlık hizmetleri sunmakta ve uzman avukatlarıyla hizmet vermektedir. Müvekkillerinin ticari sözleşmeler, şirket kuruluşları, ticari anlaşmazlıklar gibi konularda haklarını korumak ve yasal süreçlerde destek sağlamak için tanınmaktadır.
            </p>
          
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
    <AccordionTab header="Ticaret Hukuku Alanında Verilen Danışmalık Hizmetleri Nelerdir?">
        <p className="m-0">
          1-Birleşme, ayrılma işlemlerinde sürecin etkin bir şekilde yönetimi ve hukuki danışmanlık<br/>
          2-Şirketler arası ilişkilerde hukuki danışmanlık<br/>
          3-Şirket kuruluşu ve esas sözleşmenin hazırlanması, genel kurul hazırlığı, işleyişi ve karar alınması
          aşamasında hukuki ve mali danışmanlık<br/>
          4-Yabancı sermayeli şirketlerin yerel mevzuatla ve ülkemizdeki hukuki süreçlerle ilgili soruların
          çözümünde hukuki danışmanlık<br/>
          5-Şirketlerin günlük işlemlerinin hukuki uygunluğunun denetlenmesi ve bilgilendirme hususunda
          danışmanlık
        </p>
    </AccordionTab>
    <AccordionTab header="Ticaret Hukukunun Kapsam Alanı Genel Olarak Nedir?">
        <p className="m-0">
        1-Ticaret hukukunun genel kavramları; tacir, ticari işletme, ticaret sicili, ticaret unvanı, haksız rekabet,
        ticari defterler, cari hesap, ticari işler tellallığı. acentelik, ticaret ortaklıkları başlıklarında tanımlanır.<br/>
        2-Ticari Sözleşmelerin Tanzimi<br/>
        3-Ticaret ortaklıkları kolektif, komandit, anonim, limited, kooperatif ortaklıklarıdır. Ticari Belgelerle
        ilgili hukuk, kıymetli evrak başlığında toplanmıştır. Düzenlenme biçimleri nama yazılı ve hamiline yazılı
        olur.<br/>
        4-Kıymetli evrak poliçe, bono, çek, emtia senetleri, taşıma senetleri türlerindendir.<br/>
        5-Şirket Birleşmeleri ve Devralmaları, Haksız Rekabet Davaları<br/>
        6-İflasın Ertelenmesi Davaları<br/>
        7-Türk Ticaret Kanunu'ndan Doğan Sair İhtilafların Çözümü
        </p>
    </AccordionTab>
    {/* <AccordionTab header="Ticaret Hukuku nedir?">
        <p className="m-0">
            Ticaret hukuku, ticari faaliyetleri düzenleyen ve ticaret işlemlerinin yapılmasını sağlayan hukuk dalıdır. Ticaret hukuku, ticari işletmelerin kuruluşu, yönetimi, ticari sözleşmeler, ticari alacaklar ve ticaret şirketleri gibi konuları kapsar.
        </p>
    </AccordionTab>
    <AccordionTab header="Ticaret Hukuku nedir?">
        <p className="m-0">
            Ticaret hukuku, ticari faaliyetleri düzenleyen ve ticaret işlemlerinin yapılmasını sağlayan hukuk dalıdır. Ticaret hukuku, ticari işletmelerin kuruluşu, yönetimi, ticari sözleşmeler, ticari alacaklar ve ticaret şirketleri gibi konuları kapsar.
        </p>
    </AccordionTab>
    <AccordionTab header="Ticaret işletmesi ve ticari işletme yönetimi nasıl düzenlenir?">
        <p className="m-0">
            Ticaret işletmesi, kar amacı güden ve ticari faaliyetlerde bulunan işletmeleri ifade eder. Ticari işletme yönetimi ise ticaret hukuku kurallarına göre yönetilir ve ticari defterlerin tutulması gibi yükümlülükleri içerir.
        </p>
    </AccordionTab>
    <AccordionTab header="Ticari sözleşmeler ve türleri nelerdir?">
        <p className="m-0">
            Ticari sözleşmeler, ticari faaliyetlerde bulunan işletmeler arasında yapılan sözleşmelerdir. Satış sözleşmeleri, kira sözleşmeleri, taşıma sözleşmeleri gibi çeşitli türleri bulunur.
        </p>
    </AccordionTab>
    <AccordionTab header="Ticaret sicili ve ticari kayıtlar nasıl tutulur?">
        <p className="m-0">
            Ticaret sicili, ticaret işletmelerinin kaydedildiği resmi kayıt sistemidir. Ticari kayıtların doğru ve düzenli tutulması ticari işletmeler için önemli bir yükümlülüktür.
        </p>
    </AccordionTab>
    <AccordionTab header="Ticaret hukuku ve rekabet hukuku ilişkisi nedir?">
        <p className="m-0">
            Ticaret hukuku ve rekabet hukuku, piyasada adil rekabeti sağlamayı amaçlar. Rekabet yasağı, tekel karşıtı düzenlemeler gibi konular ticaret hukuku içinde önemli yer tutar.
        </p>
    </AccordionTab>
    <AccordionTab header="Ekşioğlu Hukuk Bürosu'nda ticaret hukuku alanında nasıl hizmet verilmektedir?">
        <p className="m-0">
            Ekşioğlu Hukuk Bürosu, müvekkillerine ticari sözleşmelerin hazırlanması, ticaret hukuku uyuşmazlıklarında hukuki danışmanlık, ticaret işletmelerinin kuruluşu ve yönetimi konusunda destek sağlamaktadır.
        </p>
    </AccordionTab> */}
</Accordion>



          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default TicaretHukuku;
