import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const RekabetHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | REKABET HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>REKABET HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.webp")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            Rekabet hukuku, mal ve hizmet piyasalarında rekabetin korunması amacına yönelik olarak oluşturulan kurallar üzerine inşa edilmiş bir hukuk dalıdır. Mal veya hizmet piyasalarında iktisadi faaliyet gösteren teşebbüslerin eylem ve işlemlerini konu alan bu kurallar genel olarak üç başlık altında yer almaktadır. Bu çerçevede rekabet kuralları teşebbüsler arasındaki rekabeti sınırlayan anlaşma, karar ve uyumlu eylemler ile pazarında hâkim durumda olan teşebbüslerin bu durumlarını kötüye kullanmalarını yasaklamakta, belirli eşiklerin üzerindeki birleşme ve devralmaları ise kontrole tabi tutmaktadır.
            </p>
            <br></br>
            <p>
            Rekabet incelemeleri, rekabete uyum programlarının uygulanması, birleşme ve devralmalar, her türlü sözleşme ve bayilik sistemlerinin dizaynı konusunda müvekkillerimize gerekli tüm hukuki desteği sağlamakla birlikte müvekkillerimizin karşı karşıya kalabilecekleri her türlü rekabet incelemesinde, rekabet hukuku konusundaki tecrübesini, sektörel düzenlemeler alanındaki bilgilerimiz de beraber değerlendirerek Rekabet Kurumu nezdindeki tüm aşamalarda temsil etmekteyiz. Ayrıca Kurum kararlarına karşı açılacak her türlü davalarda da gerekli tüm aşamalarda yanınıza yer almaktayız.
            </p>
            <br></br>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
    <AccordionTab header="Hangi konularda şikayet başvurusu yapılabilir?">
        <p className="m-0">
        Rekabet Yasası’nın 4,6 veya 7. Maddelerinin ihlal edildiği iddiası ile Rekabet Kurulu’na şikayette bulunulabilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Kimler şikayette bulunabilir?">
        <p className="m-0">
        Şikayet konusu ile ilgili hukuki veya ekonomik çıkarı olan herhangi bir gerçek veya tüzel kişi; ekonomik, ticari veya mesleki örgütler; tüketici örgütleri; şikayette bulunabilirler.
        </p>
    </AccordionTab>
    <AccordionTab header="İhlallerle ilgili olarak Rekabet Kurulu re’sen işlem başlatabilir mi?">
        <p className="m-0">
        Rekabet kurulu piyasadaki rekabet aksaklıklarını tespit ettiğinde re’sen işlem başlatma yetkisine sahiptir.
        </p>
    </AccordionTab>
    <AccordionTab header="Şikayet başvurularında hangi bilgi ve belgeler yer almalıdır?">
        <p className="m-0">
        - Şikayette bulunan gerçek veya tüzel kişinin açık adı, adresi ve iletişim bilgileri<br/>
        - Hakkında işlem başlatılması için şikayette bulunulan teşebbüsün açık adı, adresi ve iletişim bilgileri<br/>
        - Şikayete neden olan gerçekler veya uygulamalar hakkında bilgi ve /veya belgeler ve/veya kanıtlar.
        </p>
    </AccordionTab>
    <AccordionTab header="Hangi uygulamalar Rekabet Yasası kapsamında değildir?">
        <p className="m-0">
        Teşebbüslerin ilgili yasalara aykırı olarak kaçak işçi çalıştırması, yurda kaçak getirilmiş malların satılması, vergi ödemekten kaçınması gibi durumların yarattığı KAYIT DIŞILIK yanında, sattığı üründe bulunmayan özellikleri varmış gibi pazarlaması, kalite-standart ve güvenlik konularında gerekli koşulları sağlamaması veya sağlamış gibi göstermesi şeklinde ortaya çıkan ve teşebbüsler arasında HAKSIZ REKABET yaratan uygulamalar ile yurt dışından ithal edilen ve ithal edildiği ülke fiyatları altında satışa sunulan DAMPİNGLİ satış uygulamaları Rekabet Yasası kapsamına girmemektedir.
        </p>
    </AccordionTab>
    <AccordionTab header="Muafiyet talep başvurusunu kimler, ne maksatla yapabilir?">
        <p className="m-0">
        Bir anlaşmanın veya teşebbüs birliği kararının veya uyumlu eylemin yasal açıdan uygun olduğunu ve Rekabet Yasası’nın 5. Maddesi uyarınca muaf tutulabileceğini kanıtlamak üzere muafiyet arayan teşebbüsler veya teşebbüs birliklerinden herhangi biri Rekabet Kurulu’na başvuruda bulunabilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Yoğunlaşma nedir?">
        <p className="m-0">
        Yoğunlaşma, iki veya daha fazla teşebbüsün birleşmesi veya bir veya birden fazla teşebbüsün tamamının ya da bir kısmının üzerindeki doğrudan veya dolaylı kontrolün bir veya daha fazla teşebbüs veya halihazırda en az bir teşebbüsü kontrol eden bir veya daha fazla kişi tarafından devralınması veya iki veya daha fazla teşebbüsün bağımsız bir ekonomik birimin tüm işlevlerini kalıcı olarak yerine getirecek şekilde bir ortaklık oluşturması Rekabet Yasası kapsamında yoğunlaşma işlemi sayılmaktadır.
        </p>
    </AccordionTab>
    <AccordionTab header="Tüm yoğunlaşmalar bildirime ve izne tabi midir?">
        <p className="m-0">
        Yoğunlaşma işlemleri aşağıda belirtilen durumların varlığı halinde Rekabet Kurulu’na bildirim yapılıp izin alınmadan uygulamaya konulamaz.
        </p>
    </AccordionTab>
    {/* <AccordionTab header="Rekabet Hukuku nedir?">
        <p className="m-0">
            Rekabet hukuku, piyasadaki rekabetin korunmasını sağlayan ve haksız rekabeti önleyen hukuk dalıdır. Rekabet hukuku, tekel karşıtı düzenlemeler, rekabet ihlalleri ve rekabet otoriteleri tarafından uygulanan kuralları içerir.
        </p>
    </AccordionTab>
    <AccordionTab header="Rekabet yasağı ve tekel karşıtı düzenlemeler nelerdir?">
        <p className="m-0">
            Rekabet yasağı, piyasadaki serbest rekabetin korunmasını amaçlayan hükümleri kapsar. Tekel karşıtı düzenlemeler ise tekel oluşturabilecek faaliyetleri sınırlar.
        </p>
    </AccordionTab>
    <AccordionTab header="Rekabet ihlalleri ve cezaları nelerdir?">
        <p className="m-0">
            Rekabet ihlalleri, haksız rekabet ve rekabeti engelleyici anlaşmaları içerir. Rekabet ihlallerinde para cezaları, idari tedbirler ve rekabetin yeniden tesis edilmesi gibi cezalar uygulanabilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Rekabet otoriteleri ve görevleri nelerdir?">
        <p className="m-0">
            Rekabet otoriteleri, rekabetin korunması ve izlenmesi görevini üstlenir. Bu otoriteler, piyasa gözetimi, rekabet ihlalleri ile ilgili soruşturmalar yapar ve gerekli tedbirleri alır.
        </p>
    </AccordionTab>
    <AccordionTab header="Rekabet hukuku ve tüketici hakları ilişkisi nedir?">
        <p className="m-0">
            Rekabet hukuku, adil rekabet ortamının oluşturulmasıyla tüketicilerin haklarını korur. Tüketici hakları, rekabetin sağlıklı işlemesini destekleyen unsurlar arasında yer alır.
        </p>
    </AccordionTab>
    <AccordionTab header="Ekşioğlu Hukuk Bürosu'nda rekabet hukuku alanında nasıl hizmet verilmektedir?">
        <p className="m-0">
            Ekşioğlu Hukuk Bürosu, müvekkillerine rekabet ihlalleri konusunda hukuki danışmanlık, tekel karşıtı düzenlemeler çerçevesinde hukuki destek, rekabet otoriteleri ile iletişim ve soruşturma süreçlerinde temsil hizmetleri sunmaktadır.
        </p>
    </AccordionTab> */}
</Accordion>



          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default RekabetHukuku;
