import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const IcraIflasHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
      <title>HİZMETLERİMİZ | İCRA İFLAS HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>İCRA İFLAS HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Aile Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/icraHukuku.webp")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            İcra ve İflas Kanunu, medeni usul mevzuatının ayrılmaz bir parçası olup amacı, borçlunun usul hukuku kapsamında elde ettiği hakları yerine getirmemesi halinde alacaklının alacağının gerçekleşmesini sağlamaktır. Alacaklıya, mahkeme kararı olsun ya da olmasın, talebini icra dairesine ileterek borçluya karşı olan talebini alma imkanı sunulmaktadır.
            </p>
            <br></br>
            <p>
            Maddi alacak (ya da teminat) ise dava açılmadan mahkeme kararı olmadan da icra yapılabilir. Para makbuzu olmamasına rağmen kiralık mülklerin tahliyesi için de benzer bir yöntem düzenlendi. Diğer iddialar için dava açılması ve dava sonunda verilen hükmün uygulanması gerekir. Yargılamamanın ve kararların infazının üçüncü bir yaygın yöntemi, menkul ve menkul ipoteklerin paraya dönüştürülmesidir. Tüm bu genel yöntemler, faturanın türüne ve mahiyetine göre bazı alt yöntemlere ayrılmaktadır.
            </p>
            


            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
            <AccordionTab header="Ödeme emrine süresinde itiraz etmezsem ne olur?">
              <p className="m-0">
              Ödeme emrinin tebliğinden itibaren 7 gün içinde itiraz edilmezse, icra takibi kesinleşir. Takibin kesinleşmesi ile alacaklı haciz işlemlerine başlayabilecektir. Bundan sonra borçlu ancak, menfi tespit davası açarak borçlu olmadığını ispatlayabilir. Menfi tespit davası açmakta kendiliğinde icra takibini durdurmayacaktır.
              </p>
            </AccordionTab>
            <AccordionTab header="Nelere haciz konulur?">
              <p className="m-0">
              İcra takibinin kesinleşmesinin ardından alacaklı icra dairesine başvurarak, borçluya ait mal varlığının araştırılmasını talep eder. Bu malvarlığı, borçluya ait ev, arsa gibi taşınmaz olabileceği gibi, araba ve benzeri taşınır bir mal da olabilir. Bunların dışında, borçlunun sigortalı bir işte çalışıp çalışmadığı araştırılarak, çalışması halinde aldığı ücretin ¼’üne haciz konulması amacıyla işyerine haciz müzekkeresi gönderilir. Borçlunun, posta çeki veya banka hesabı olması halinde de yine PTT ve ilgili bankalara, hesapta para bulunması halinde bunların icra dosyasına gönderilmesi için haciz yazısı gönderilir. Borçlunun murisinden kalan malvarlığındaki hissesine de haciz konulabilir.
              </p>
            </AccordionTab>
            <AccordionTab header="İlamlı icra ile ilamsız icranın farkı nedir?">
              <p className="m-0">
              İlamlı icra bir mahkeme kararının icra takibine konu edilmesi iken, ilamsız icra mahkeme kararı dışındaki herhangi bir sebebe dayanılarak başlatılan icra takibidir. İlamsız icra takibine itiraz icra dairesine yapılırken, ilamlı icrada icra mahkemesine şikayette bulunulabilir. İlamsız icraya itiraz takibi kendiliğinden durdururken, ilamlı icraya karşı yapılan şikayet, takibi kendiliğinden durdurmaz.
              </p>
            </AccordionTab>
            {/* <AccordionTab header="Aile Hukuku nedir?">
              <p className="m-0">
                Aile hukuku, aile içi ilişkileri ve bu ilişkilerden doğan hukuki sonuçları düzenleyen bir hukuk dalıdır. Bu alan, evlilik, boşanma, velayet, mal rejimi, nafaka ve soybağı gibi konuları kapsar.
              </p>
            </AccordionTab>
            <AccordionTab header="Aile Hukuku kimleri kapsar?">
              <p className="m-0">
                Aile hukuku, evli çiftler, boşanmış eşler, çocuklar, ebeveynler ve diğer aile bireyleri arasındaki hukuki ilişkileri kapsar. Aile içi anlaşmazlıkların çözümünde ve aile bireylerinin haklarının korunmasında önemli bir rol oynar.
              </p>
            </AccordionTab>
            <AccordionTab header="Aile Hukuku neden önemlidir?">
              <p className="m-0">
                Aile hukuku, aile bireylerinin hak ve yükümlülüklerini düzenler ve korur. Aile içi anlaşmazlıkların çözümü, çocukların haklarının korunması ve aile içi huzurun sağlanması açısından kritik öneme sahiptir.
              </p>
            </AccordionTab>
            <AccordionTab header="Boşanma davalarında neler yapılabilir?">
              <p className="m-0">
                Boşanma davalarında, evliliğin sona erdirilmesi, nafaka talepleri, velayet ve mal paylaşımı konuları ele alınır. Ekşioğlu Hukuk Bürosu, müvekkillerine boşanma sürecinde hukuki destek sağlamaktadır.
              </p>
            </AccordionTab>
            <AccordionTab header="Velayet davalarında ne yapılabilir?">
              <p className="m-0">
                Velayet davalarında, çocuğun hangi ebeveynle kalacağı ve ebeveynlerin çocuğa dair hak ve sorumlulukları belirlenir. Çocuğun en iyi menfaatlerinin gözetilmesi esastır ve Ekşioğlu Hukuk Bürosu bu süreçte müvekkillerine profesyonel hukuki destek sunar.
              </p>
            </AccordionTab>
            <AccordionTab header="Nafaka nedir ve nasıl talep edilir?">
              <p className="m-0">
                Nafaka, boşanma sonrası bir eşin diğerine veya çocuklarına maddi destek sağlamasıdır. Nafaka talebi, mahkemeye başvurarak yapılır ve nafakanın miktarı mahkeme tarafından belirlenir. Ekşioğlu Hukuk Bürosu, nafaka talepleri konusunda hukuki danışmanlık ve temsil hizmeti sunmaktadır.
              </p>
            </AccordionTab>
            <AccordionTab header="Aile içi şiddet durumunda neler yapılabilir?">
              <p className="m-0">
                Aile içi şiddet durumunda, şiddet mağdurları korunma talepleriyle mahkemeye başvurabilir ve koruyucu tedbirler talep edebilir. Ekşioğlu Hukuk Bürosu, aile içi şiddet durumlarında hukuki destek ve danışmanlık hizmeti sunar.
              </p>
            </AccordionTab>
            <AccordionTab header="Aile hukuku danışmanlık süreci nasıl işler?">
              <p className="m-0">
                Aile hukuku danışmanlık süreci, müvekkilin ihtiyaç ve durumunun değerlendirilmesiyle başlar. Ardından, hukuki gereklilikler doğrultusunda danışmanlık hizmeti sunulur ve gerekli hukuki adımlar atılır. Ekşioğlu Hukuk Bürosu, bu sürecin her aşamasında profesyonel destek sağlar.
              </p>
            </AccordionTab>
            <AccordionTab header="Ekşioğlu Hukuk Bürosu'na kimler başvurabilir?">
              <p className="m-0">
                Ekşioğlu Hukuk Bürosu'na, aile hukuku alanında hukuki danışmanlık ve destek almak isteyen herkes başvurabilir. Evli çiftler, boşanmış eşler, ebeveynler ve diğer aile bireyleri hukuki hizmetlerden faydalanabilirler.
              </p>
            </AccordionTab> */}
          </Accordion>



          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default IcraIflasHukuku;
