import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const IsHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | SÖZLEŞMELER VE BORÇLAR HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SÖZLEŞMELER VE BORÇLAR HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/sozlesmeVeBorclar.webp")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
                <p>
                Borçlar hukuku, bireyler arasındaki borç ilişkilerini düzenleyen hukuk dalıdır. Taraflar arasındaki borç ilişkilerinin doğuşunu, hükümlerini ve sona erme şekillerini belirler. Bu hukuk dalı, özellikle günlük yaşamda sıklıkla karşılaşılan borç ve alacak meselelerine dair kuralları kapsar. Bireyler arasındaki ticari ve ticari olmayan borç ilişkilerini, sözleşmeler, haksız fiiller ve sebepsiz zenginleşme gibi çeşitli yollarla ele alır. Yalnızca mal ve hizmetlerin devir, değişim ve dolaşımını sağlamakla yetinmez aynı zamanda kişileri; kusurlu veya hukuka aykırı davranışlarla zarar verici fiillere (haksız fiil) karşı da korumayı amaçlar ve bu kişilerin zararlarının giderilmesi amacına da hizmet eder.
                </p>
                <br></br>
                <p>
                Sözleşmeler hukuku, borçlar hukukunun önemli bir alt dalıdır ve iki veya daha fazla taraf arasında yapılan anlaşmaların geçerliliğini, hükümlerini ve sonuçlarını düzenler. Tarafların hak ve yükümlülüklerini belirler ve bu anlaşmaların nasıl yürürlüğe girdiğini, geçerli olabilmesi için hangi şartların yerine getirilmesi gerektiğini ve tarafların haklarını nasıl koruyabileceklerini açıklar. Sözleşmeler hukuku, kira sözleşmeleri, satış sözleşmeleri, hizmet sözleşmeleri ve iş sözleşmeleri gibi çok çeşitli anlaşmaları kapsar.
                </p>
                
                

            </Col>
          </Row>
          <Row>
            
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="12"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
                <p>
                Borçlar ve sözleşmeler hukuku, aynı zamanda taraflar arasındaki anlaşmazlıkların çözümüne de odaklanır. Taraflar arasındaki güveni korumak ve ticari işlemlerin istikrarını sağlamak amacıyla önemli bir rol oynar. Bir sözleşmenin ihlal edilmesi durumunda, tarafların nasıl bir çözüm yolu izleyeceğini belirler. Bu genellikle dava açma, tazminat talep etme veya sözleşmenin feshi gibi hukuki yolları içerir. Bu hukuk dalları, hem bireylerin hem de işletmelerin haklarını koruma ve yasal anlaşmazlıkların adil bir şekilde çözülmesi açısından hayati öneme sahiptir.
                </p>
                

            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
            <AccordionTab header="Sözleşmeler ve Borçlar Hukuku nedir?">
                <p className="m-0">
                Sözleşmeler ve borçlar hukuku, taraflar arasında yapılan anlaşmaların
                düzenlenmesi ve borç ilişkilerinin hukuki çerçevede belirlenmesini
                sağlayan bir hukuk dalıdır.
                </p>
            </AccordionTab>
            <AccordionTab header="Sözleşmeler ve Borçlar Hukuku kimleri kapsar?">
                <p className="m-0">
                Sözleşmeler ve borçlar hukuku, bireyler, şirketler, kurumlar ve çeşitli
                organizasyonlar arasındaki hukuki ilişkileri kapsar. Her türlü ticari ve
                özel anlaşmalar bu hukuk dalı kapsamında değerlendirilir.
                </p>
            </AccordionTab>
            <AccordionTab header="Sözleşmeler ve Borçlar Hukuku neden önemlidir?">
                <p className="m-0">
                Bu hukuk dalı, taraflar arasında yapılan anlaşmaların hukuki geçerliliğini
                ve uygulanabilirliğini sağlar. Anlaşmazlık durumlarında hak ve
                yükümlülüklerin belirlenmesi ve korunması açısından kritik öneme sahiptir.
                </p>
            </AccordionTab>
            <AccordionTab header="Sözleşme hazırlarken nelere dikkat edilmelidir?">
                <p className="m-0">
                Sözleşme hazırlarken tarafların hak ve yükümlülüklerinin açıkça
                belirtilmesi, sözleşmenin yasalara uygun olması ve tarafların özgür iradesi
                ile imzalanmış olması gerekmektedir. Ayrıca, sözleşmenin yazılı olarak
                yapılması ve detaylı hükümler içermesi önemlidir.
                </p>
            </AccordionTab>
            <AccordionTab header="Borçlar Hukuku hangi konuları kapsar?">
                <p className="m-0">
                Borçlar hukuku, borç ilişkileri, alacak ve verecek davaları, tazminat
                yükümlülükleri, kira sözleşmeleri ve ticari ilişkilerdeki borçlanma
                konularını kapsar. Bu hukuk dalı, borçlu ve alacaklı haklarını koruma
                altına alır.
                </p>
            </AccordionTab>
            <AccordionTab header="Sözleşme ihlallerinde neler yapılabilir?">
                <p className="m-0">
                Sözleşme ihlallerinde, taraflar hukuki yollara başvurarak haklarını
                arayabilirler. Bu süreçte, sözleşmenin feshi, tazminat talebi ve diğer
                hukuki çözümler devreye girebilir. Ekşioğlu Hukuk Bürosu, bu süreçte
                müvekkillerine gerekli hukuki desteği sağlamaktadır.
                </p>
            </AccordionTab>
            <AccordionTab header="Sözleşme hazırlama süreci nasıl işler?">
                <p className="m-0">
                Sözleşme hazırlama süreci, tarafların ihtiyaç ve beklentilerinin
                değerlendirilmesiyle başlar. Ardından, hukuki gereklilikler göz önünde
                bulundurularak sözleşme taslağı hazırlanır ve tarafların onayına sunulur.
                Ekşioğlu Hukuk Bürosu, bu sürecin her aşamasında profesyonel danışmanlık
                hizmeti sunar.
                </p>
            </AccordionTab>
            <AccordionTab header="Sözleşmelerin geçerlilik süresi ne kadardır?">
                <p className="m-0">
                Sözleşmelerin geçerlilik süresi, sözleşme maddelerine ve tarafların
                anlaşmasına bağlı olarak değişir. Belirli bir süre için yapılmış
                sözleşmeler, sürenin dolmasıyla sona erer. Süresiz sözleşmeler ise
                tarafların fesih hakkı saklı kalmak koşuluyla devam eder.
                </p>
            </AccordionTab>
            <AccordionTab header="Ekşioğlu Hukuk Bürosu'na kimler başvurabilir?">
                <p className="m-0">
                Ekşioğlu Hukuk Bürosu'na, ticari veya bireysel sözleşme ihtiyacı olan
                herkes başvurabilir. Şirketler, kurumlar, bireyler ve organizasyonlar,
                hukuki danışmanlık ve sözleşme hazırlama hizmetlerinden faydalanabilirler.
                </p>
            </AccordionTab>
            </Accordion>

          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default IsHukuku;
