import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const GayrimenkulHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | GAYRİMENKUL VE KAT MÜLKİYETİ HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>GAYRİMENKUL HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.webp")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            Ekşioğlu Hukuk Bürosu olarak; gayrimenkul projelerinin tapu tescil davaları, satın alma süreçleri, imar ve kira nedeniyle doğan ihtilaflar ile tahliye davaları başta olmak üzere İmar ve Gayrimenkul Hukukunun her alanında müvekkillerimize hizmet sunmaktayız.
            </p>
            <br></br>
            <p>
            Uzman kadromuz ile Site Yönetim süreçleri alanında dava ve danışmanlık hizmeti vermekteyiz. Ayrıca Kat Mülkiyeti Hukukundan kaynaklanan her türlü dava ile site yönetimi genel kurul karar süreçleri faaliyetlerini yürütmekteyiz.
            </p>
            <br></br>
            <p>
            Mülkiyet hakkının temelini oluşturan Gayrimenkul Hukuku son derece kapsamlı bir alandır. Taşınmazlar için düzenlenen bu hukuk dalı toplumun her kesimini ilgilendirmektedir. Bu süreçte gerek danışmanlık faaliyeti olarak süreç yönetimi gerekse uyuşmazlıkların çözümü noktasında yargıya başvuruda bulunurken hukuki destek almak son derece önemli bir gerekliliktir. Ekşioğlu Hukuk Bürosu olarak konusunda uzman ve deneyimli avukat kadromuz ile Gayrimenkul ve Kat Mülkiyeti Hukukundan kaynaklanan uyuşmazlıkları en hızlı ve güvenilir şekilde çözüme ulaştırmaktayız. 
            </p>


            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
            <AccordionTab header="Tapu İptali ve Tescili Davası Nedir?">
                <p className="m-0">
                Tapu iptali ve tescil davası, tapu sicilindeki gerçeği yansıtmayan hak sahipliği durumunun düzeltilmesine ilişkindir. Tapu iptali ve tescil davasında amaç, tapu sicilinin gerçeğe uygun hale getirilmesi ve mülkiyet hakkı kapsamında hak kaybının önlenmesidir. 
                </p>
            </AccordionTab>
            <AccordionTab header="Ecri misil davası nedir?">
                <p className="m-0">
                Ecrimisil, diğer adıyla haksız işgal tazminatı; zilyet olmayan malikin, malik olmayan kötüniyetli zilyetten isteyebileceği tazminattır. Bir maldan kullanma, yararlanma hakkı olmayan kişinin bu malı haksız olarak kullanması veya maldan haksız olarak yararlanması halinde malın sahibine ödemek zorunda kalacağı tazminat bedelidir. Ecri misil davası ise haksız işgalin neticesinde meydana gelen zararın giderilmesi için açılan bir davadır.
                </p>
            </AccordionTab>
            <AccordionTab header="Ortaklığın giderilmesi (İzale-i şuyu) davası nedir?">
                <p className="m-0">
                Ortaklığın giderilmesi diğer adıyla İzale-i şuyu davası, taşınır veya taşınmaz üzerinde paylı ya da elbirliği mülkiyetin sona erdirilmesi için açılan davadır. Malın paylı mülkiyet veya elbirliğiyle mülkiyet halinde birden çok kimsenin mülkiyetinde bulunması söz konusu olabilmektedir. Bu hallerde malın kullanılması ve maldan yararlanılması farklı hukuki kurallara tabidir. Bu doğrultuda, söz konusu mala ilişkin fikir ayrılığında bulunan paydaşlar aralarında mevcut olan mülkiyet ilişkisinin bireysel olarak değiştirilmesini, bunun mümkün olmadığı hallerde ise ortaklığın satış suretiyle giderilmesini talep ettiği davadır.
                </p>
            </AccordionTab>
            {/* <AccordionTab header="Gayrimenkul Hukuku nedir?">
                <p className="m-0">
                    Gayrimenkul hukuku, taşınmaz malların mülkiyeti, edinimi, kullanımı ve devri ile ilgili hukuki düzenlemeleri içeren bir hukuk dalıdır.
                </p>
            </AccordionTab>
            <AccordionTab header="Gayrimenkul Hukuku konuları neleri kapsar?">
                <p className="m-0">
                    Gayrimenkul hukuku, tapu işlemleri, mülkiyet hakları, kiracı ve ev sahibi ilişkileri, ipotek ve rehin gibi konuları kapsar. Ayrıca, gayrimenkulün devri ve miras yoluyla geçişi de bu hukuk dalının alanına girer.
                </p>
            </AccordionTab>
            <AccordionTab header="Gayrimenkul edinimi nasıl gerçekleşir?">
                <p className="m-0">
                    Gayrimenkul edinimi, satış sözleşmesi, miras yoluyla intikal, bağışlama veya devir gibi çeşitli hukuki işlemlerle gerçekleşir. Her edinim şekli belirli hukuki prosedürlere tabidir.
                </p>
            </AccordionTab>
            <AccordionTab header="Gayrimenkul kiralaması nasıl yapılır?">
                <p className="m-0">
                    Gayrimenkul kiralaması, kiracı ve ev sahibi arasında yapılan sözleşmeye dayanır. Kiralama süresi, kira bedeli, kiracının hakları ve taşınmazın kullanım koşulları bu sözleşmeyle belirlenir.
                </p>
            </AccordionTab>
            <AccordionTab header="Tapu işlemleri ve önemi nedir?">
                <p className="m-0">
                    Tapu işlemleri, gayrimenkulün mülkiyetini kanıtlayan belgelerin düzenlenmesi ve devir işlemlerinin resmileştirilmesidir. Tapu işlemleri, taşınmazın güvenliği ve mülkiyet haklarının korunması açısından büyük önem taşır.
                </p>
            </AccordionTab>
            <AccordionTab header="Gayrimenkul hukuku danışmanlık süreci nasıl işler?">
                <p className="m-0">
                    Gayrimenkul hukuku danışmanlık süreci, taşınmaz mal sahipleri, kiracılar, yatırımcılar ve emlak işletmelerinin ihtiyaçlarına göre şekillenir. Hukuki danışmanlık, tapu işlemleri, kiralama sözleşmeleri, ipotek ve diğer konularda destek sağlar.
                </p>
            </AccordionTab>
            <AccordionTab header="Gayrimenkul yatırımı ve hukuki açıdan dikkat edilmesi gerekenler nelerdir?">
                <p className="m-0">
                    Gayrimenkul yatırımı yaparken tapu kaydı, imar durumu, kira getirisi, vergi yükümlülükleri gibi hukuki ve ekonomik faktörler dikkate alınmalıdır. Ekşioğlu Hukuk Bürosu, yatırımcılara bu konularda hukuki danışmanlık sağlar.
                </p>
            </AccordionTab>
            <AccordionTab header="Ekşioğlu Hukuk Bürosu'na gayrimenkul hukuku konusunda kimler başvurabilir?">
                <p className="m-0">
                    Ekşioğlu Hukuk Bürosu'na gayrimenkul hukukuyla ilgili olarak taşınmaz mal sahipleri, kiracılar, yatırımcılar ve emlak işletmeleri başvurabilir. Tapu işlemleri, kiralama sözleşmeleri, ipotek ve mülkiyet hakları konusunda profesyonel hukuki destek sunulur.
                </p>
            </AccordionTab> */}
          </Accordion>

          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default GayrimenkulHukuku;
